import React from "react";
import Box from "./Box.js";

class News extends React.Component {
  state = {
    isLoading: true,
    newsItems: [],
  };

  componentDidMount() {
    this.fetchNews();
  }

  render() {
    const { isLoading, newsItems } = this.state;
    const ReactMarkdown = require("react-markdown");

    return (
      <div>
        <h1 className="text-xl font-semibold py-4 pl-4">Nyheter</h1>
        {isLoading && "Laddar..."}
        {newsItems.map((item, key) => (
          <div className="mb-4" key={key}>
            <Box key={key}>
              <div>
                <h3 className="text-md font-semibold">{item.title}</h3>
                <ReactMarkdown children={item.body} />
              </div>
            </Box>
          </div>
        ))}
      </div>
    );
  }

  fetchNews() {
    fetch("https://api.trailchallenge.se/news")
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          newsItems: data,
          isLoading: false,
        })
      );
  }
}

export default News;

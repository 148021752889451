import React, { useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";

function CoursesMap() {
  const zoom = 14;
  const [map, setMap] = useState();
  const mapContainer = useRef(null);
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (courses.length === 0) {
      fetch(`https://api.trailchallenge.se/courses_with_geojson`)
        .then((res) => res.json())
        .then((res) => setCourses(res))
        .then((res) => setIsLoading(false));
    }
  }, [courses]);

  // Set up the map
  useEffect(() => {
    const initializeMap = ({ setMap, mapContainer }) => {
      mapboxgl.accessToken =
        "pk.eyJ1Ijoia2xhc3BldGVyc3NvbiIsImEiOiJja2FtOXBuZzEweWM3MnhvNnY5Nnd1MGNsIn0.SQ-2te7LRAiW74ZKCbKdtA";
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/klaspetersson/cko0c51wg2yop17pjsj1ksxn7", // stylesheet location
        center: [11.910279, 57.660581],
        zoom: 5,
      });

      map.on("load", () => {
        setMap(map);
        map.resize();

        courses.forEach((course) => {
          if (course.visibility === "PUBLIC") {
            map.addSource(course.id, {
              type: "geojson",
              data: JSON.parse(course.geojson),
            });

            map.addLayer({
              id: course.id + "cp-fill",
              type: "fill",
              source: course.id,
              paint: {
                "fill-color": "#FF00FF",
                "fill-opacity": 0.3,
              },
              filter: ["==", "$type", "Polygon"],
            });

            map.addLayer({
              id: course.id + "cp-stroke",
              type: "line",
              source: course.id,
              paint: {
                "line-color": "#FF00FF",
                "line-width": 2,
              },
              filter: ["==", "$type", "Polygon"],
            });

            // Create a default Marker and add it to the map.
            const checkpoints = geojson_checkpoints(JSON.parse(course.geojson));
            var marker1 = new mapboxgl.Marker()
              .setLngLat([
                checkpoints[0].points[0].long,
                checkpoints[0].points[0].lat,
              ])
              .addTo(map);
          }
        });
      });
    };

    if (!map) {
      initializeMap({ setMap, mapContainer });
    }
  }, [map, zoom, courses]);

  const geojson_checkpoints = (geojson) => {
    let checkpoints = [];
    const features = geojson["features"];
    let i = 0;

    features.forEach((feature) => {
      let coordinateLists = feature["geometry"]["coordinates"];
      coordinateLists.forEach((coordinateList) => {
        let points = [];
        coordinateList.forEach((coordinate) => {
          points.push({
            lat: coordinate[1],
            long: coordinate[0],
          });
        });
        let checkpoint = {
          number: i,
          points: points,
          name: checkpoint_name(feature["properties"], i),
        };
        checkpoints.push(checkpoint);
        i++;
      });
    });
    return checkpoints;
  };

  const checkpoint_name = (properties, index) => {
    // Handle bad server data
    if (properties["name"] === "Unnamed Layer") {
      properties["name"] = null;
    }

    if (properties["name"] != null) {
      return properties["name"];
    }

    if (properties["category"] === "start") {
      return "Start";
    }

    if (properties["category"] === "goal") {
      return "Mål";
    }

    return index;
  };

  return (
    <div>
      <div
        ref={(el) => (mapContainer.current = el)}
        className="mapContainer w-screen h-screen"
      />
    </div>
  );
}

export default CoursesMap;

import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AppHeader from "./AppHeader.js";
import News from "./News.js";
import CoursesMap from "./CoursesMap.js";

function App() {
  return (
    <Router>
      <div class="w-auto md:w-6/12 mx-auto py-8">
        <Switch>
          <Route path="/banor">
            <CoursesMap />
          </Route>
          <Route path="/">
            <div className="px-4 ">
              <div className="space-y-4 rounded-xl bg-white p-4 shadow">
                <h1 className="text-4xl text-green font-semibold">
                  Trail Challenge
                </h1>

                <p className="text-lg font-semibold">
                  Hitta nya stigar att springa på
                </p>

                <p>
                  Trail Challenge hjälper dig hitta nya stigar att springa på.
                  Slipp asfalt och tråkiga motionsspår. Upptäck stigarna i
                  skogen istället. Utmana dina vänner och se vem som är
                  snabbast.
                </p>

                <p>
                  I appen finns det flera olika banor att välja mellan. Alla är
                  designade och provsprungna för att vara så bra som möjligt.
                  För att klara en "challenge" behöver du besöka alla
                  checkpoints och sedan gå i mål. När du springer kontrollerar
                  appen din position och vilka checkpoints du besöker.
                </p>

                <p>
                  När du är redo att springa en bana klickar du på
                  "Spring"-knappen. Om du inte redan står i startområdet kommer
                  appen att visa vart du ska. Starten är gul och målet är grönt.
                  Varje checkpoint måste besökas i rätt ordning för att räknas.
                </p>

                <p>
                  När du besöker en checkpoint får du en ljudsignal och
                  checkpointen markeras som tagen. När du tagit alla checkpoints
                  och gått i mål registreras ditt resultat.
                </p>

                <div className="content-start space-y-4">
                  <div className="">
                    <a href="https://apps.apple.com/se/app/trail-challenge/id1533527058">
                      <img
                        style={{ width: 180, height: 60 }}
                        className=""
                        src="appstore-badge.svg"
                      />
                    </a>
                  </div>
                  <div className="">
                    <a href="https://play.google.com/store/apps/details?id=se.trailchallenge">
                      <img
                        style={{ width: 195, height: 60 }}
                        className=""
                        src="google-play-badge.png"
                      />
                    </a>
                  </div>
                </div>
              </div>

              <br />
              <News />
            </div>
          </Route>
        </Switch>
        <div className="px-4">
          <a href="/privacy">Privacy</a>
        </div>
      </div>
    </Router>
  );
}

export default App;
